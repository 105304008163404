import Observer from './vendor/observer.min.js'
import { formsPackage } from './functions/form.js'
import { masksPackage } from './functions/masks.js'

export const OBSERVER = Observer.getInstance()

/*
addEventListener('DOMContentLoaded', () => {
  initFormBuilder()
})
*/

export const initFormBuilder = () => {
  let classeContainerFormBuilder = '.c-form-container'
  formsPackage(classeContainerFormBuilder)
  masksPackage()
  initFileUpload(classeContainerFormBuilder)
  recaptchaFormsCallback()
}

window.initFormBuilder = initFormBuilder
